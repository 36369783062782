import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Link } from "gatsby"
import LotOne from "../../Data/lot-1.json"
import LotTwo from "../../Data/lot-2.json"
import LotThree from "../../Data/lot-3.json"
import LotFour from "../../Data/lot-4.json"
import LotFive from "../../Data/lot-5.json"


const Container = styled.div`
    width: 100%;
    margin: auto;
    background-color: white;
    text-align: center;
   .photo{
       width: 50%;
       display: inline-block;
   }
   .lot-container{
       padding-top: 25px;
       padding-bottom: 25px;
   }
   .text{
       width: 50%; 
       display: inline-block;
      
       vertical-align: top;
   }
   .inner-text{
       width: 80%;
       margin: auto;
       text-align: left;
   }
   h1{
       font-family: bookmania;
       font-weight: 600;
       font-size: 2em;
       line-height: 50px;
       margin-top: 100px;
   }
   p{
       font-family: open sans;
       font-weight: 300;
       margin-top: 10px;
   }
   .price{
       font-family: open sans, geneva;
       font-weight: 800;
       font-size: 1.1em;
       color: #DB995A;
   }
   .inner-text{
       text-align:left;
       width: 80%;
   }
   .buttons{
       margin-top: 20px;
   }
   .lot-m{
       display: none;
   }
   .bg-gray{
    background-color: #F5F5F4;
   }
   @media(max-width: 820px){
        .lot-d{
            display: none;
        }
        .lot-m{
            display: block;
        }
        .text, .photo{
            display: block;
            width: 100%;
        }
        h1{
            margin-top: 0px;
        }
        .text{
            height: auto;
            padding: 60px 0px;
        }
        
   }
`
const ImgBox = styled(Img)`
   width: 100%;
   height: 100%;
   padding: 0;
   
`

const ImgBoxD = styled(Img)`
   width: 90%;
   margin: auto;
   max-height 450px;
   padding: 0;
   
`
const LearnButton = styled(Link)`
   color: white; 
   font-family: open sans, geneva;
   font-weight: 100;
   font-size: 1em;
   text-align: center;
   background: #5F5F5F;
   padding: 8px 20px;
   text-transform: uppercase;
   letter-spacing: 1px;
   display: inline-block;
   margin-right: 10px;
`
const LearnButtonDis = styled(Link)`
   color: white; 
   font-family: open sans, geneva;
   font-weight: 100;
   font-size: 1em;
   text-align: center;
   background: #c9c9c9;
   padding: 8px 20px;
   text-transform: uppercase;
   letter-spacing: 1px;
   display: inline-block;
   margin-right: 10px;
`

const ContactButton = styled(Link)`
   color: white; 
   font-family: open sans, geneva;
   font-weight: 100;
   font-size: 1em;
   text-align: center;
   background: #77966D;
   padding: 8px 20px;
   text-transform: uppercase;
   letter-spacing: 1px;
   display: inline-block;
`

function LotSection(){
    const data = useStaticQuery(graphql`
    query {
      img_one: file(relativePath: { eq: "lot-2.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img_two: file(relativePath: { eq: "4-creekside-oaks.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img_three: file(relativePath: { eq: "5-casa-de-campo.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img_four: file(relativePath: { eq: "6-creekside-oaks.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img_five: file(relativePath: { eq: "creek-2.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
    return(
        <Container>
            <div className="lot-container bg-gray lot-d">
                <div className="text right">
                    <div className="inner-text">
                        <h1>{ LotFive.title }</h1>
                        { LotFive.available &&
                            <div className="price">SOLD</div>
                        }
                        { !LotFive.available &&
                            <div className="price">SOLD</div>
                        }
                        <p>{ LotFive.short_desc }</p>
                        <div className="buttons">
                            <LearnButtonDis to="/lots" >Lot Details</LearnButtonDis>
                            <ContactButton to="/contact">Contact</ContactButton>
                        </div>
                    </div>
                </div>
                <div className="photo">
                <ImgBoxD fluid={data.img_five.childImageSharp.fluid} />
                </div>
            </div>
            <div className="lot-container  lot-d">
                <div className="text right">
                    <div className="inner-text">
                        <h1>{ LotFour.title }</h1>
                        { LotFour.available &&
                            <div className="price">{ LotOne.price}</div>
                        }
                        { !LotFour.available &&
                            <div className="price">SOLD</div>
                        }
                        <p>{ LotFour.short_desc }</p>
                        <div className="buttons">
                            <LearnButton to="/lot-4">Lot Details</LearnButton>
                            <ContactButton to="/contact">Contact</ContactButton>
                        </div>
                    </div>
                </div>
                <div className="photo">
                <ImgBoxD fluid={data.img_four.childImageSharp.fluid} />
                </div>
            </div>
            <div className="lot-container bg-gray lot-d">
                <div className="text right">
                    <div className="inner-text">
                        <h1>{ LotThree.title }</h1>
                        { LotThree.available &&
                            <div className="price">{ LotThree.price}</div>
                        }
                        { !LotThree.available &&
                            <div className="price">SOLD</div>
                        }
                        <p>{ LotThree.short_desc }</p>
                        <div className="buttons">
                            <LearnButton to="/lot-3">Lot Details</LearnButton>
                            <ContactButton to="/contact">Contact</ContactButton>
                        </div>
                    </div>
                </div>
                <div className="photo">
                <ImgBoxD fluid={data.img_three.childImageSharp.fluid} />
                </div>
            </div>
            <div className="lot-container  lot-d">
                <div className="text right">
                    <div className="inner-text">
                        <h1>{ LotTwo.title }</h1>
                        { LotTwo.available &&
                            <div className="price">{ LotTwo.price}</div>
                        }
                        { !LotTwo.available &&
                            <div className="price">SOLD</div>
                        }
                        <p>{ LotTwo.short_desc }</p>
                        <div className="buttons">
                            <LearnButton to="/lot-2">Lot Details</LearnButton>
                            <ContactButton to="/contact">Contact</ContactButton>
                        </div>
                    </div>
                </div>
                <div className="photo">
                <ImgBoxD fluid={data.img_two.childImageSharp.fluid} />
                </div>
            </div>
            <div className="lot-container bg-gray lot-d">
                <div className="text">
                    <div className="inner-text">
                        <h1>{ LotOne.title}</h1>
                        { LotOne.available &&
                            <div className="price">{ LotOne.price}</div>
                        }
                        { !LotOne.available &&
                            <div className="price">SOLD</div>
                        }
                        <p>{ LotOne.short_desc }</p>
                        <div className="buttons">
                            <LearnButton to="/lot-1">Lot Details</LearnButton>
                            <ContactButton to="/contact">Contact</ContactButton>
                        </div>
                    </div>
                </div>
                <div className="photo">
                    <ImgBoxD fluid={data.img_one.childImageSharp.fluid} />
                </div>
            </div>

            <div className="lot-container  lot-m">
                <div className="text right">
                    <div className="inner-text">
                        <h1>8 Creekside Oaks</h1>
                        <div className="price"> SOLD </div>
                        <p>{ LotFour.short_desc }</p>
                        <div className="buttons">
                            <LearnButton >Lot Details</LearnButton>
                            <ContactButton to="/contact">Contact</ContactButton>
                        </div>
                    </div>
                </div>
                <div className="photo">
                <ImgBox fluid={data.img_four.childImageSharp.fluid} />
                </div>
            </div>
            <div className="lot-container lot-m">
                <div className="text right">
                    <div className="inner-text">
                        <h1>{ LotFour.title }</h1>
                        { LotFour.available &&
                            <div className="price">{ LotFour.price}</div>
                        }
                        { !LotFour.available &&
                            <div className="price">SOLD</div>
                        }
                        <p>{ LotFour.short_desc }</p>
                        <div className="buttons">
                            <LearnButton to="/lot-4">Lot Details</LearnButton>
                            <ContactButton to="/contact">Contact</ContactButton>
                        </div>
                    </div>
                </div>
                <div className="photo">
                <ImgBox fluid={data.img_four.childImageSharp.fluid} />
                </div>
            </div>
            <div className="lot-container lot-m">
                <div className="text right">
                    <div className="inner-text">
                        <h1>{ LotThree.title }</h1>
                        { LotThree.available &&
                            <div className="price">{ LotThree.price}</div>
                        }
                        { !LotThree.available &&
                            <div className="price">SOLD</div>
                        }
                        <p>{ LotThree.short_desc }</p>
                        <div className="buttons">
                            <LearnButton to="/lot-3">Lot Details</LearnButton>
                            <ContactButton to="/contact">Contact</ContactButton>
                        </div>
                    </div>
                </div>
                <div className="photo">
                    <ImgBox fluid={data.img_three.childImageSharp.fluid} />
                </div>
            </div>
            <div className="lot-container lot-m">
                <div className="text right">
                    <div className="inner-text">
                        <h1>{ LotTwo.title }</h1>
                        { LotTwo.available &&
                            <div className="price">{ LotTwo.price}</div>
                        }
                        { !LotTwo.available &&
                            <div className="price">SOLD</div>
                        }
                        <p>{ LotTwo.short_desc }</p>
                        <div className="buttons">
                            <LearnButton to="/lot-2">Lot Details</LearnButton>
                            <ContactButton to="/contact">Contact</ContactButton>
                        </div>
                    </div>
                </div>
                <div className="photo">
                <ImgBox fluid={data.img_two.childImageSharp.fluid} />
                </div>
            </div>
            <div className="lot-container lot-m">
                <div className="text">
                    <div className="inner-text">
                        <h1>{ LotOne.title }</h1>
                        { LotOne.available &&
                            <div className="price">{ LotOne.price}</div>
                        }
                        { !LotOne.available &&
                            <div className="price">SOLD</div>
                        }
                        <p>{ LotOne.short_desc }</p>
                        <div className="buttons">
                            <LearnButton to="/lot-1">Lot Details</LearnButton>
                            <ContactButton to="/contact">Contact</ContactButton>
                        </div>
                    </div>
                </div>
                <div className="photo">
                    <ImgBox fluid={data.img_one.childImageSharp.fluid} />
                </div>
            </div>
            <div className="lot-container lot-m">
                <div className="text">
                    <div className="inner-text">
                        <h1>{ LotFive.title }</h1>
                        { LotFive.available &&
                            <div className="price">{ LotOne.price}</div>
                        }
                        { !LotFive.available &&
                            <div className="price">SOLD</div>
                        }
                        <p>{ LotFive.short_desc }</p>
                        <div className="buttons">
                            <LearnButtonDis to="/lots" >Lot Details</LearnButtonDis>
                            <ContactButton to="/contact">Contact</ContactButton>
                        </div>
                    </div>
                </div>
                <div className="photo">
                    <ImgBox fluid={data.img_one.childImageSharp.fluid} />
                </div>
            </div>
        </Container>
    )
}

export default LotSection
