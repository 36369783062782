import React from "react"
import styled from "styled-components"


const Container = styled.div`
    text-align: center;
    background-color: white;
    padding: 40px 0px;
    h1{
        font-family: bookmania;
        font-weight: 600;
        font-size: 3.2em;
    }
`

function PageTitle(props){
    return(
        <Container>
            <h1>{props.title}</h1>
        </Container>
    )
}

export default PageTitle
