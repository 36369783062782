import React from "react"

import AltLayout from "../components/altlayout"
import SEO from "../components/seo"

import PageTitle from "../components/PageTitle/pagetitle"
import LotSection from "../components/LotSection/lotsection"
import Footer from "../components/Footer/footer"

const LotPage = () => (
  <AltLayout>
    <SEO title="Lots" />
    <PageTitle title="Our Properties" />
    <LotSection />
    <Footer />
  </AltLayout>
)

export default LotPage
